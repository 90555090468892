<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-lx-cascades"></i> 基本配置</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="form-box">
				<el-form :model="emailData" ref="emailData" :rules="rules" label-width="150px">
					<el-form-item label="是否开启活动">
						<el-switch v-model="emailData.status"></el-switch>
					</el-form-item>
					<el-form-item label="标题" prop="title">
						<el-input v-model="emailData.title" style="width:350px"></el-input>
					</el-form-item>
					<el-form-item label="数据填充" prop="data">
						<el-input v-model="emailData.data" style="width:350px"></el-input>
					</el-form-item>
					<el-form-item label="数据填充2" prop="data">
						<el-input v-model="emailData.data2" style="width:350px"></el-input>
					</el-form-item>
					<el-form-item label="数据填充3" prop="data">
						<el-input v-model="emailData.data3" style="width:350px"></el-input>
					</el-form-item>
					<el-form-item label="活动开始" prop="start_date">
					   <el-date-picker type="date" placeholder="选择日期" v-model="emailData.start_date" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
					</el-form-item>
					<el-form-item label="活动结束" prop="end_date">
					   <el-date-picker type="date" placeholder="选择日期" v-model="emailData.end_date" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
					</el-form-item>
					<el-form-item label="活动图">
						<el-upload
								class="avatar-uploader"
								name="image"
								with-credentials
								:data="{id:this.emailData.imgid}"
								:action="uploadUrl()"
								:on-error="uploadError"
								:on-success="handleAvatarSuccess2"
								:before-upload="beforeAvatarUpload"
								:on-progress="uploading"
								:show-file-list="false"
								:auto-upload="true"
								enctype="multipart/form-data">
							<img style="width: 100px;" v-if="emailData.b_image" :src="emailData.b_image" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
						<span style="color:red">建议尺寸550*550</span>
					</el-form-item>
					<el-form-item label="二维码背景图片">
						<el-upload
								class="avatar-uploader"
								name="image"
								with-credentials
								:data="{id:this.emailData.back_img}"
								:action="uploadUrl()"
								:on-error="uploadError"
								:on-success="handleAvatarSuccess"
								:before-upload="beforeAvatarUpload"
								:on-progress="uploading"
								:show-file-list="false"
								:auto-upload="true"
								enctype="multipart/form-data">
							<img style="width: 100px;" v-if="emailData.backimgUrl" :src="emailData.backimgUrl" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</el-form-item>
					<el-form-item label="活动规则" style="width:1000px">
						<vue-ueditor-wrap v-model="emailData.description" :config="myConfig"></vue-ueditor-wrap>
					</el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit('emailData')">确定</el-button>
                    </el-form-item>
				</el-form>
            </div>
        </div>

    </div>
</template>

<script>
    import 'quill/dist/quill.core.css';
    import 'quill/dist/quill.snow.css';
    import 'quill/dist/quill.bubble.css';
    import {quillEditor, Quill} from 'vue-quill-editor'
    import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module';
    Quill.register('modules/ImageExtend', ImageExtend)
    export default {
        name: 'baseform',
        components: {
            quillEditor
        },
        data: function(){
            return {
				emailData:{
					 id:'',
					 title:'',
					 president_fir:'',
					 president_fir_img:'',
					 imgid: null,
					 b_image: null,
					 status: '',
					 back_img: null,
					 backimgUrl: null,
				},
				tableData: [],
                loading:false,
				myConfig: {
				    // 编辑器不自动被内容撑高
				    autoHeightEnabled: false,
				    // 初始容器高度
				    initialFrameHeight: 600,
				    // 初始容器宽度
				    initialFrameWidth: '90%',
				    zIndex: 9999,
				    // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
				    serverUrl: this.$api.editorUrl,
				    // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
				    UEDITOR_HOME_URL: '/ueditor/'
				},
				rules: {
					title: [
				        { required: true, message: '请输入标题', trigger: 'blur' }
				    ],
					start_date: [
						{ required: true, message: '活动开始时间', trigger: 'blur' }
					],
					end_date:[
						{ required: true, message: '活动结束时间', trigger: 'blur' }
					],
				},
                // 富文本框参数设置
                editorOption: {
                    modules: {
                        ImageExtend: {
                            loading: true,
                            name: 'image',
                            action: this.$api.uploadUrl+"/Images/uploadEditorImage",
                            response: (res) => {
                                return res.data
                            }
                        },
                        toolbar: {
                            container: container,
                            handlers: {
                                'image': function () {
                                    QuillWatch.emit(this.quill.id)
                                }
                            }
                        }
                    }
                },
            }
        },
        created() {
            this.getData();
        },
        methods: {
            onEditorChange({ editor, html, text }) {
                this.form.details = html;
            },
			handleAvatarSuccess2(res, file){
				this.loading=false;
			    this.emailData.imgid=res.data;
			    this.emailData.b_image = URL.createObjectURL(file.raw);

			    //this.getData();
				this.handleConfig();
			    this.$message.success(res.msg);
			},			//图片上传成功
			//设置上传图片接口地址
			uploadUrl(){
			  var url=this.$api.uploadUrl + "/Images/upload";
			  return url
			},
			handleClick(tab, event) {
			  // console.log(tab, event);
			},
			//图片上传之前
			beforeAvatarUpload(file){
			  // console.log(file);
			    this.loading=true;
			},
			//正在上传中
			uploading(event, file, fileList){
			  // console.log(event);
			  // console.log(file);
			  // console.log(fileList);
			},
			//图片上传失败
			uploadError(err){
			  this.$message.error(err.msg);
			},
			//图片上传成功
			handleAvatarSuccess(res, file){
			  this.loading=false;
			  //console.log(res);
			  this.emailData.back_img=res.data;
			  //console.log(res.data);
			  
			  this.emailData.backimgUrl = URL.createObjectURL(file.raw);
			  this.$message.success(res.msg);
			},			  //图片上传成功

            //获取初始数据
            getData() {

				var params = this.$qs.stringify({
					activity_type:"mianmo_activity",
				})
				this.dialogEmail = true
				this.$api.post('ShopActivity/system_set', params,res=>{
					this.emailData =  res.data.list
				 
				},err => {
				this.emailData = []
				this.$message.error(err.msg);
				});



            },
            onSubmit(formName) {
				
                this.$refs[formName].validate((valid) => {
                    if (valid) {
						if(!this.emailData.title){
							this.$message.error('请输入标题');
							return false;
						}
						if(!this.emailData.start_date){
							this.$message.error('请选择开始时间');
							return false;
						}
						if(!this.emailData.end_date){
							this.$message.error('请选择结束时间');
							return false;
						}
						var params=this.$qs.stringify({
							status:this.emailData.status ? 1 : 0,
							activity_type:"mianmo_activity",
							title:this.emailData.title,
							data:this.emailData.data,
							data2:this.emailData.data2,
							data3:this.emailData.data3,
							start_date:this.emailData.start_date,
							end_date:this.emailData.end_date,
							description:this.emailData.description,
							imgid:this.emailData.imgid,
							back_img: this.emailData.back_img,
						});

						this.$api.post('ShopActivity/saveSetting', params, res => {
							this.dialogEmail =  false;
							this.$message.success(res.msg);
							this.getData();
						}, err => {
							this.$message.error(err.msg);
						});
				
                    }else{
                        console.log("请填写所需数据");
                        return false;
                    }
                });		
            },
            //将转移符号替换为html
            escapeStringHTML(str) {
                if(str){
                    str = str.replace(/&lt;/g,'<');
                    str = str.replace(/&gt;/g,'>');
                    str = str.replace(/&quot;/g,'"');
                }
                return str;
            },
            submit(){
                let str=this.escapeStringHTML(this.form.details);
                // console.log(str);
            },
        }
    }
</script>

<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 100%;
        /*height: 100%;*/
        display: block;
    }
    .el-upload--picture-card{
        width: 100% !important;
        height: auto !important;
    }
</style>
